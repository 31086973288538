<template lang="pug">
  main#main.manager-contacts-link_networks
    b-container(fluid)
      wc-breadcrumb(icon='fal fa-share-alt')
      section.l-section
        .l-section-h
          router-view
</template>

<script>
import WcBreadcrumb from '@components/shared/WcBreadcrumb'

export default {
  name: 'manager-contacts-link_networks',
  components: {
    WcBreadcrumb,
  },
}
</script>
